import React, {useState} from "react"
import {Container,Row,Col,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import SEO from "../../components/seo"

import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 

import Photographer from  "../../components/modules/360-photographer";

import {config} from '../../components/Common/constant';

import { useContainerContext } from "../../store/ContainerContext";

const PresentationPage = () => {
  const context = useContainerContext();
  const { property_data } = context.state;

    return <>
        <SEO title="Photography Date and Time" description="Photography Date and Time"/>

        <div className="pre-wrapper team_wrapper">
        <Header />

        {property_data && <div className="section-wrapper custom_present_inner pt-0"><Photographer property_data={property_data} /></div> }

        <NavLink tab_name="Photographer" prevLink={property_data && parseInt(property_data.price_expectancy) > 200000 ? config.custom_plan : config.custom_pho_datetime} nextLink={config.custom_comparables} />
        {/* footer-wrapper */}
      </div>
  </>
}

export default PresentationPage
